import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Collapse } from "@material-ui/core";
import { defaultToEmptyString, handleEvent } from "gx-npm-lib";
import { BaseTextArea, MiniButton, TooltipV2 } from "gx-npm-ui";
import { VendorOptOutIcon } from "gx-npm-icons";
import { MessageIconWithToolTip } from "../../../../../../../ui/messageIcon";
import { ListHeader, ListHeaderExpandButton } from "../../../../../../../ui/list/header";
import ScoringOptOutDialog from "../requirement-score-opt-out-dialog/requirement-score-opt-out-dialog.component";
import ScoringPopover from "../../scoring-popover/scoring-popover.component";
import RequirementScorePill from "../requirement-score-pill/requirement-score-pill.component";
import styles from "./requirement-score-header.styles";
import { headerHeights } from "../../../../../../../lib";
import { useScoreFormContext } from "../../../context/score-form-context";

type RequirementScoreHeaderProps = {
  comments: string;
  headerExpand: boolean;
  isOptedOutAll?: boolean;
  isProcessing?: boolean;
  isScoringLevelCategory?: boolean;
  isViewOnly?: boolean;
  name: string;
  onOptOut?: () => void;
  onSaveComment?: (value: string) => void;
  onSaveScore?: (value: number) => void;
  score: number;
  headerSetExpand: (val: boolean) => void;
};

const useStyles = makeStyles(() => styles);
const RequirementScoreHeader: React.FC<RequirementScoreHeaderProps> = ({
  comments,
  headerExpand = false,
  isOptedOutAll = false,
  isProcessing = false,
  isScoringLevelCategory = false,
  isViewOnly = false,
  name,
  onOptOut = () => {},
  onSaveComment = () => {},
  onSaveScore = () => {},
  score,
  headerSetExpand,
}) => {
  const [isCommentEntered, setIsCommentEntered] = useState(false);
  const [isCommentShown, setIsCommentShown] = useState(false);
  const [isOpenOptOut, setIsOpenOptOut] = useState(false);
  const { showAllComments } = useScoreFormContext();
  const [h5P1Height, setH5P1Height] = useState(0);
  const [headerWrapperHeight, setHeaderWrapperHeight] = useState(0);
  const h5P1Ref = useRef<HTMLDivElement>(null);
  const headerWrapperRef = useRef<HTMLDivElement>(null);
  const h5P1HeightTwoLines = 44;
  const headerWrapperPreWrapHeight = 87;

  const { t } = useTranslation();

  const classes = useStyles();

  const stickyPosition = headerHeights.navigation + headerHeights.vendorInfo.mediaStandard;

  useEffect(() => {
    if (showAllComments) {
      setIsCommentShown(true);
    } else {
      setIsCommentShown(false);
    }
  }, [showAllComments]);

  useEffect(() => {
    const handleResize = () => {
      if (h5P1Ref.current !== null) {
        setH5P1Height(h5P1Ref.current.offsetHeight);
      }
      if (headerWrapperRef.current !== null) {
        setHeaderWrapperHeight(headerWrapperRef.current.offsetHeight);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [h5P1Ref, h5P1Height]);
  return (
    <ListHeader
      renderItem={(expand, setExpand) => (
        <div className={classNames(classes.headerRoot)}>
          <div className={classNames(classes.top)}>
            <div
              ref={headerWrapperRef}
              className={classNames(classes.headerWrap, h5P1Height > h5P1HeightTwoLines && "multiline-centered")}
            >
              <ListHeaderExpandButton
                collapseLabel={t("Collapse section")}
                expand={expand}
                expandLabel={t("Expand section")}
                rootClassName="gx-scorecard-expand-button"
                setExpand={setExpand}
              />
              <div ref={h5P1Ref} className={classNames(classes.h5OrP1element, classes.carbon)}>
                {name || t("Untitled category")}
              </div>
              {isScoringLevelCategory && (
                <ScoringPopover
                  isViewOnly={isViewOnly}
                  onSelectScore={(value) => handleEvent(onSaveScore, value)}
                  rootClassName="gx-cat-scoring-popover"
                  score={score}
                />
              )}
              {!isViewOnly && !isScoringLevelCategory && (
                <React.Fragment>
                  <ScoringOptOutDialog
                    isOpen={isOpenOptOut}
                    onClose={() => {
                      setIsOpenOptOut(false);
                    }}
                    onConfirm={() => {
                      setIsOpenOptOut(false);
                      handleEvent(onOptOut);
                    }}
                    productName={name}
                  />
                  <MiniButton
                    disabled={isOptedOutAll}
                    onClick={() => {
                      setIsOpenOptOut(true);
                    }}
                    rootClassName={classNames(
                      classes.buttonOptOut,
                      headerWrapperHeight > headerWrapperPreWrapHeight && "opt-out-button-wrap"
                    )}
                  >
                    <VendorOptOutIcon />
                    <TooltipV2
                      placement={"top"}
                      PopperProps={{ modifiers: { offset: { offset: `0, 12` } } }}
                      title={t(
                        "Opting out of scoring this category will remove any scores you’ve previously entered for this category and the overall team score for this category will be calculated without your input."
                      )}
                    >
                      <div>{t("Opt out")}</div>
                    </TooltipV2>
                  </MiniButton>
                </React.Fragment>
              )}
            </div>
            <div className={classNames(classes.right)}>
              {!isScoringLevelCategory && (
                <RequirementScorePill
                  isOptedOutAll={!isViewOnly && isOptedOutAll}
                  isProcessing={isProcessing}
                  rootClassName={classNames(!isViewOnly && "collaboration")}
                  score={score}
                />
              )}

              <MessageIconWithToolTip
                isOpen={isCommentShown}
                hasComments={!!(comments || isCommentEntered)}
                handleClick={() => setIsCommentShown((prev) => !prev)}
                isViewOnly={isViewOnly}
              />
            </div>
          </div>
          <Collapse in={isCommentShown}>
            <div className={classNames(classes.bottom, showAllComments && classes.bottomShowAllComments)}>
              <BaseTextArea
                disabled={isViewOnly}
                flavor="OUTLINED"
                handleAutoSave={(value: string) => handleEvent(onSaveComment, value)}
                helperText={t("Comments")}
                minRows={1}
                onChange={(e: { target: { value: string } }) => setIsCommentEntered(!!e?.target?.value)}
                placeholder=" "
                rootClassName="gx-scorecard-list-header-comments"
                value={defaultToEmptyString(comments)}
              />
            </div>
          </Collapse>
        </div>
      )}
      expand={headerExpand}
      setExpand={headerSetExpand}
      sticky={stickyPosition}
    />
  );
};

export default RequirementScoreHeader;
