import { Collapse } from "@material-ui/core";
import { ArrowCircledIcon } from "gx-npm-icons";
import {
  getStoredJsonItem,
  InitUserRole,
  postAsyncRequest,
  putAsyncRequest,
  setStoredJsonItem,
  UUID,
} from "gx-npm-lib";
import { BaseTextArea, IconButton, SnackbarBanner, TooltipV2, TypographyComponent } from "gx-npm-ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useScoresheetContext } from "../../../../context/scoresheet.context";
import styles from "./sidebar-comment-v2.styles.module.scss";

const TEXTAREA_ROWS = 7;
const SidebarCommentV2Component = () => {
  const { t } = useTranslation();
  const [hasSaveError, setHasSaveError] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isViewOnly, setIsViewOnly] = useState(true);
  const [sessionStorageId, setSessionStorageId] = useState("");
  const [textAreaId, setTextAreaId] = useState<UUID | "">("");
  const [textAreaValue, setTextAreaValue] = useState<string>("");
  const {
    initiativeId,
    initProductId,
    isEvaluator,
    keyTakeawaysDesc,
    keyTakeawaysId,
    setScoresheetEditCount,
    userRole,
  } = useScoresheetContext();

  useEffect(() => {
    if (!initProductId) {
      return;
    }
    setSessionStorageId(`workspace-scorecard-expand-collapse-${initProductId}`);
  }, [initProductId]);

  useEffect(() => {
    if (!sessionStorageId) {
      return;
    }
    const sessionStorage = getStoredJsonItem(sessionStorageId);
    const storedExpandValue = sessionStorage?.showOverallComment !== false;
    setIsExpanded(storedExpandValue);
  }, [sessionStorageId]);

  useEffect(() => {
    if (!keyTakeawaysDesc) {
      return;
    }
    setTextAreaValue(keyTakeawaysDesc);
  }, [keyTakeawaysDesc]);

  useEffect(() => {
    if (!keyTakeawaysId) {
      return;
    }
    setTextAreaId(keyTakeawaysId);
  }, [keyTakeawaysId]);

  useEffect(() => {
    const roles: (InitUserRole | "")[] = [InitUserRole.CONTRIBUTOR, InitUserRole.OWNER];
    const isViewer = roles.indexOf(userRole) === -1;
    setIsViewOnly(isViewer || !isEvaluator);
  }, [isEvaluator, userRole]);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
    if (!sessionStorageId) {
      return;
    }
    const sessionData = getStoredJsonItem(sessionStorageId);
    setStoredJsonItem(sessionStorageId, { ...sessionData, showOverallComment: !isExpanded });
  };

  const handleSave = async (value: string) => {
    setTextAreaValue(value);
    setScoresheetEditCount((prev) => prev + 1);

    const urlEnd = textAreaId ? `${textAreaId}/user` : "user";
    const url = `/api/v2/initiatives/${initiativeId}/products/${initProductId}/comments/SCORECARD_KEY_TAKEAWAYS/${urlEnd}`;
    const asyncReq = textAreaId ? putAsyncRequest : postAsyncRequest;
    const response = await asyncReq(url, { description: value });
    if (!textAreaId && response?.status === 201 && !!response.data?.data?.id) {
      setTextAreaId(response.data.data.id);
    } else if (response?.status !== 200) {
      setHasSaveError(true);
    }
    setScoresheetEditCount((prev) => prev - 1);
  };

  return (
    <div className={styles.commentWrapper}>
      <div className={styles.commentHeader}>
        <TooltipV2 title={isExpanded ? t("Collapse section") : t("Expand section")}>
          <div>
            <IconButton ariaLabel={isExpanded ? t("Click to collapse") : t("Click to expand")} onClick={handleClick}>
              <ArrowCircledIcon />
            </IconButton>
          </div>
        </TooltipV2>
        <TypographyComponent boldness="semi" rootClassName={styles.headingText} styling="p2">
          {t("Key takeaways")}
        </TypographyComponent>
      </div>
      <Collapse in={isExpanded}>
        <div className={styles.inputWrapper}>
          <BaseTextArea
            disabled={isViewOnly}
            flavor="OUTLINED"
            handleAutoSave={handleSave}
            minRows={TEXTAREA_ROWS}
            placeholder={t("Enter your key takeaways for this vendor.")}
            value={textAreaValue}
          />
        </div>
      </Collapse>
      <SnackbarBanner
        isOpen={hasSaveError}
        message={t("There was an issue saving your changes.")}
        setIsOpen={() => setHasSaveError(false)}
      />
    </div>
  );
};

export default SidebarCommentV2Component;
