import { colorPalette, weightPalette } from "gx-npm-common-styles";

const scoringPopoverStyles = {
  popoverWrapper: {
    display: "flex",
    "& .gx-scorecard-scoring-popover": {
      border: `1px solid ${colorPalette.neutrals.stone.hex}`,
      marginLeft: 76,
      marginRight: 20,
      padding: "12px 12px 12px 0",
      width: 172,
      "&.open": {
        border: `2px solid ${colorPalette.interactions.blueBerry.hex}`,
        padding: "11px 11px 11px 0",
      },
      "& p": {
        color: colorPalette.neutrals.iron.hex,
        fontSize: "16px",
        fontWeight: `${weightPalette.regular.amount} !important`,
        fontVariationSettings: `${weightPalette.regular.variation} !important`,
      },
      "& .gx-popover-menu-selected": {
        width: "100%",
        "&.carbon": {
          "& p": {
            color: colorPalette.neutrals.carbon.hex,
          },
        },
      },
      "& .gx-popover-menu-list": {
        left: "-2px",
        top: 52,
        width: 173,
      },
    },
    "& .tool-tip": {
      display: "flex",
      width: "unset",
    },
  },
  popoverWrapperGCOM3695: {
    "& .gx-scorecard-scoring-popover": {
      "&.open": {
        border: `2px solid ${colorPalette.interactions.defaultCta.hex}`,
      },
    },
  },
  requirementScoreTooltip: {
    width: "265px",
    top: "-5px !important",
    left: "21px !important",
    textAlign: "center" as const,
  },
};

export default scoringPopoverStyles;
