import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { formatDecimalToString } from "gx-npm-lib";
import { SpinningNumber } from "../../../../../../../ui/spinningNumber";
import styles from "./requirement-score-pill.styles";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../../../../../../lib/feature-flags";

const useStyles = makeStyles(() => styles);
const RequirementScorePill = ({ isOptedOutAll = false, isProcessing = false, rootClassName = "", score = 0 }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classNames("semi-bold", classes.categoryScore, rootClassName)}>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent
            styling={"p3"}
            boldness={"semi"}
            color={"carbon"}
            rootClassName={classNames(classes.categoryScoreNameGCOM3606)}
          >
            {isOptedOutAll ? t("Opted out") : t("My category score ")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <p className={classNames("p3", "semi-bold", classes.categoryScoreName)}>
            {isOptedOutAll ? t("Opted out") : t("My category score ")}
          </p>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      {!isOptedOutAll && <SpinningNumber isSpinning={isProcessing} value={formatDecimalToString(score, 0, 2)} />}
    </div>
  );
};

export default RequirementScorePill;
