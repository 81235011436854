import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorHuckleberry = colorPalette.accent.huckleberry.hex;
const colorCarbon = colorPalette.neutrals.carbon.hex;
const colorSilver = colorPalette.neutrals.silver.hex;

const scoreProgressStyles = {
  scoreContainer: {
    backgroundColor: colorHuckleberry,
    borderRadius: "8px",
    marginBottom: "24px",
    padding: "24px",
    width: "345px",
    "&.screened-out, &.screened-out a": {
      color: colorCarbon,
    },
    "&.screened-out, &.viewer": {
      backgroundColor: colorSilver,
    },
  },
  title: {
    fontSize: "14px",
    fontVariationSettings: weightPalette.semiBold.variation,
    fontWeight: weightPalette.semiBold.amount,
    lineHeight: "21px",
    marginBottom: "20px",
  },
  titleGCOM3606: {
    marginBottom: "20px",
  },
  categoryDetails: {
    marginBottom: "12px",
  },
  scoreProgressWrapper: {
    alignItems: "center",
    display: "flex",
  },
  scoreProgressBar: {
    flexGrow: 1,
    paddingLeft: "17px",
  },
};

export default scoreProgressStyles;
