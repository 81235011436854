import React from "react";
import { useTranslation } from "react-i18next";
import { handleEvent } from "gx-npm-lib";
import {
  Button,
  ButtonLoader,
  Dialog,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../../../../../../lib/feature-flags";

type ScoringOptOutDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  productName: string;
};
const ScoringOptOutDialog: React.FC<ScoringOptOutDialogProps> = ({
  isOpen = false,
  onClose = null,
  onConfirm = null,
  productName = "",
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      handleClose={() => {
        handleEvent(onClose);
      }}
      title={t("Opt out of category?")}
      body={
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <div style={{ marginBottom: 20 }}>
              <TypographyComponent styling={"p3"} element={"span"}>
                {t("Any of the scores you have already entered for requirements in")}
              </TypographyComponent>
              <TypographyComponent
                styling={"p3"}
                element={"span"}
                boldness={"semi"}
              >{` ${productName} `}</TypographyComponent>
              <TypographyComponent styling={"p3"} element={"span"}>
                {t("will be deleted.")}
              </TypographyComponent>
            </div>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <p style={{ marginBottom: 20 }}>
              <span>{t("Any of the scores you have already entered for requirements in")}</span>
              <span className="semi-bold">{` ${productName} `}</span>
              <span>{t("will be deleted.")}</span>
            </p>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      }
      footer={
        <div style={{ display: "flex", float: "right" }}>
          {!!onClose && (
            <Button onClick={() => handleEvent(onClose)} rootClassName="btn-tertiary">
              {t("Cancel")}
            </Button>
          )}
          {!!onConfirm && (
            <div style={{ marginLeft: 24 }}>
              <ButtonLoader btnClass="primary-destructive-btn" handleButtonClick={() => handleEvent(onConfirm)}>
                {t("Opt out")}
              </ButtonLoader>
            </div>
          )}
        </div>
      }
    />
  );
};

export default ScoringOptOutDialog;
