import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { defaultToZero } from "gx-npm-lib";
import { PopoverMenu, TooltipV2, useFeatureFlag } from "gx-npm-ui";
import { getScoreMenuItemsList } from "./scoring-popover.lib";
import styles from "./scoring-popover.styles";
import { getScoreIndex } from "../../../../../../lib";
import { GCOM_3695_ctaButtonColorUpdate } from "../../../../../../lib/feature-flags";

type ScoringPopoverTypes = {
  isViewOnly?: boolean;
  onSelectScore: (value: number) => void;
  rootClassName?: string;
  score?: number;
};

const useStyles = makeStyles(() => styles);
const ScoringPopover: React.FC<ScoringPopoverTypes> = ({
  isViewOnly = false,
  onSelectScore,
  rootClassName = "",
  score = 0,
}) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const classes = useStyles();
  const { t } = useTranslation();
  const menuItems = getScoreMenuItemsList(!isViewOnly);

  const handleClick = (value: number) => {
    if (value !== score) {
      onSelectScore(!isViewOnly ? getScoreIndex(value, true) : value);
    }
  };

  return (
    <div className={classNames(rootClassName, classes.popoverWrapper, isFFGCOM3695 && classes.popoverWrapperGCOM3695)}>
      <TooltipV2
        data-testid="scoring-popover"
        deactivate={!isViewOnly}
        title={t("This action is only available to assigned evaluators.")}
        placement="top"
        rootClassName={classes.requirementScoreTooltip}
      >
        <div>
          <PopoverMenu
            disabled={isViewOnly}
            helperText={t("Score")}
            iconType="arrowDynamic"
            isBehindAppBar={false}
            isClosedOnScroll={true}
            menuItems={menuItems}
            onClick={handleClick}
            rootClassName="gx-scorecard-scoring-popover"
            selectedIndex={defaultToZero(!isViewOnly ? getScoreIndex(score) : score)}
            isDynamicWidth={false}
          />
        </div>
      </TooltipV2>
    </div>
  );
};

export default ScoringPopover;
