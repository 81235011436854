import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { v4 as uuid } from "uuid";
import { makeStyles } from "@material-ui/core";
import { defaultToZero, getRnd } from "gx-npm-lib";
import { getSpinnerStyles as getStyles } from "./styles";

const areEqual = (
  prevProps: { isSpinning: boolean; value: number },
  nextProps: { isSpinning: boolean; value: number }
) => {
  return prevProps.isSpinning === nextProps.isSpinning && prevProps.value === nextProps.value;
};

const range = 10;
const selector = "gx-spinning-number";
const spinSpeed = 65;
const timeout = { enter: spinSpeed, exit: spinSpeed };

type Timer = ReturnType<typeof setTimeout>;

const useStyles = makeStyles(() => getStyles(spinSpeed));
type SpinnerProps = {
  isSpinning?: boolean;
  value?: number | string;
};
const Spinner = ({ isSpinning = false, value = 0 }: SpinnerProps) => {
  const [spinValue, setSpinValue] = useState(defaultToZero(value));
  const [spinTimer, setSpinTimer] = useState<Timer | null>(null);

  useEffect(() => {
    let timer: Timer | null = null;
    if (isSpinning) {
      if (!spinTimer) {
        timer = setInterval(() => {
          setSpinValue(getRnd(value, range));
        }, spinSpeed);
        setSpinTimer(timer);
      }
    } else {
      if (spinTimer) {
        clearInterval(spinTimer);
        setSpinTimer(null);
      }
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isSpinning, spinTimer, value]);

  const classes = useStyles();
  return (
    <div className={classNames(classes.counter, selector)}>
      <TransitionGroup component="span">
        <CSSTransition classNames="ct-node" key={uuid()} timeout={timeout}>
          <span className="gx-count-wrapper">{isSpinning ? spinValue : value}</span>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

const SpinningNumber = React.memo(Spinner, areEqual);
export { SpinningNumber };
