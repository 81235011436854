const styles = {
  root: {
    display: "block",
    margin: "0 auto",
    maxWidth: "1396px",
    minWidth: "582px",
    width: "100%",
    "& .MuiCollapse-wrapperInner": {
      zIndex: 1000,
    },
    "& .inline-alert.warning": {
      marginBottom: "24px",
      marginTop: "0px",
    },
  },
};

export default styles;
