import { colorPalette } from "gx-npm-common-styles";
import { headerHeights } from "../../lib";

const { navigation, preview, vendorInfo } = headerHeights;
const dashesAdjustment = 25;
const cogAdjustment = 64;
const sidebarContainerWidth = 360;
const sidebarPreviewPadding = 48;
const footerHeight = 128;
const sidebarVerticalPadding = 24;
const topDefault = navigation + vendorInfo.mediaStandard;
const topDefaultScrolled = navigation + vendorInfo.mediaScrolled;
const topSmallMediaExtraPad = vendorInfo.mediaSmall - vendorInfo.mediaStandard;
const previewBannerAdjustment = 64;

const scoreContentStyles = {
  contentContainer: {
    "&.scrolled": {
      "& .gx-list-header, .gx-sidebar-container": {
        top: topDefaultScrolled,
      },
      "& .gx-sidebar-container": {
        paddingTop: "24px",
        paddingBottom: 0,
        height: `calc(100vh - ${topDefaultScrolled}px - ${footerHeight}px)`,
        overflowY: "scroll",
      },
    },
    minHeight: "100%",
    paddingLeft: "56px",
    paddingRight: "56px",
    paddingTop: "25px",
    transition: "all 0.5s",
    width: "100%",

    "&$previewBannerPadding": {
      paddingTop: "48px",
      "& $sidebarDashes": {
        height: `calc(100vh - ${preview + topDefault + dashesAdjustment + previewBannerAdjustment}px)`,
        top: preview + topDefault + topSmallMediaExtraPad + dashesAdjustment - cogAdjustment + previewBannerAdjustment,
        "@media (max-width:1239px)": {
          height: `calc(100vh - ${
            preview + topDefault + topSmallMediaExtraPad + dashesAdjustment + previewBannerAdjustment
          }px)`,
          top:
            preview + topDefault + topSmallMediaExtraPad + dashesAdjustment - cogAdjustment + previewBannerAdjustment,
        },
      },

      "& $sidebarContainer": {
        height: `calc(100vh - ${topDefault + previewBannerAdjustment}px)`,
        paddingTop: `${preview + sidebarPreviewPadding}px`,
        top: topDefault + previewBannerAdjustment,
        "@media (max-width:1239px)": {
          height: `calc(100vh - ${topDefault + topSmallMediaExtraPad + previewBannerAdjustment}px)`,
        },
      },
    },
    "@media (max-width:1024px)": {
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  },
  cardAndSidebarContainer: {
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "center",
  },
  scorecardContainer: {
    display: "inline-block",
    maxWidth: "1396px",
    minWidth: "582px",
    paddingRight: sidebarContainerWidth + sidebarVerticalPadding * 2,
    verticalAlign: "top",
    width: "100%",
  },
  sidebarContainer: {
    display: "inline-block",
    height: "100vh",
    minWidth: sidebarContainerWidth,
    position: "fixed" as string as "fixed",
    verticalAlign: "top",
    width: sidebarContainerWidth,
  },
  sidebarDashes: {
    borderLeft: `1px dashed ${colorPalette.neutrals.silver.hex}`,
    height: "100vh",
    position: "fixed" as string as "fixed",
  },
  previewBannerPadding: {},
};

export { scoreContentStyles, sidebarVerticalPadding };
