import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorApricot = colorPalette.brand.apricot.hex;
const colorCarbon = colorPalette.neutrals.carbon.hex;
const colorSourHuckleberry = colorPalette.accent.sourHuckleberry.hex;
const colorStone = colorPalette.neutrals.stone.hex;
const colorWhite = colorPalette.basic.white.hex;

const faqStyles = {
  faqSection: {
    marginTop: "28px",
    marginLeft: "-12px",
  },
  faqSectionViewer: {
    marginTop: "0px",
    marginLeft: "-12px",
    "& $faqText, $faqQuestion, $faqAnswer": {
      color: colorCarbon,
    },
  },
  iconBtn: {
    "&&": {
      marginRight: "8px",
      borderRadius: "0",
    },
    "&&:hover": {
      backgroundColor: colorApricot,
    },
    "&&:hover svg": {
      fill: colorCarbon,
    },
    "& svg": {
      transform: "rotate(180deg)",
    },
    "&.expanded svg": {
      transform: "none",
    },
  },
  divider: {
    borderTop: "1px solid " + colorSourHuckleberry,
    marginLeft: "12px",
    marginBottom: "20px",
  },
  dividerGray: {
    borderTop: "1px solid " + colorStone,
    marginLeft: "12px",
    marginBottom: "20px",
  },
  faqs: {
    marginTop: "18px",
    paddingLeft: "14px",
  },
  faqQuestion: {
    marginBottom: "8px",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: weightPalette.semiBold.amount,
    fontVariationSettings: weightPalette.semiBold.variation,
  },
  faqQuestionGCOM3606: {
    marginBottom: "8px",
  },
  faqAnswer: {
    marginBottom: "24px",
    fontSize: "14px",
    lineHeight: "21px",
  },
  faqAnswerGCOM3606: {
    marginBottom: "24px",
  },
  faqText: {
    fontWeight: weightPalette.semiBold.amount,
    fontVariationSettings: weightPalette.semiBold.variation,
  },
  scorecardLink: {
    "&:not(.viewer)": {
      "&.secondary p": {
        color: colorWhite,
      },
      "& svg path": {
        fill: colorWhite,
      },
      "&:hover": {
        "&.secondary p": {
          color: colorCarbon,
        },
        "& svg path": {
          fill: colorCarbon,
        },
      },
    },
  },
};

export default faqStyles;
