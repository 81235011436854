import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Collapse } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { defaultToEmptyString, defaultToZero, handleEvent } from "gx-npm-lib";
import {
  BaseTextArea,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { reqComm, reqScore } from "../../../../../../../context/lib";
import { MessageIconWithToolTip } from "../../../../../../../ui/messageIcon";
import { getScorePriority } from "../../scoring-popover/scoring-popover.lib";
import ScoringPopover from "../../scoring-popover/scoring-popover.component";
import styles from "./requirement-score-row.styles";
import { useScoreFormContext } from "../../../context/score-form-context";
import { GCOM_3606__fontUpdate } from "../../../../../../../lib/feature-flags";

type RequirementScoreRowProps = {
  comments: string;
  description: string;
  isScoringLevelItem?: boolean;
  isViewOnly?: boolean;
  name: string;
  onSave: (type: string, value: number | string) => void;
  priority: number;
  score: number;
};
const useStyles = makeStyles(() => styles);
const RequirementScoreRow: React.FC<RequirementScoreRowProps> = ({
  comments,
  description,
  isScoringLevelItem = false,
  isViewOnly = false,
  name,
  onSave,
  priority,
  score,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [comment, setComment] = useState("");
  const [showComment, setShowComment] = useState(false);
  const { showAllComments, showAllDescriptions } = useScoreFormContext();

  useEffect(() => {
    setShowComment(showAllComments);
  }, [showAllComments]);

  const handleCommentsButtonClick = () => {
    setShowComment((prev) => !prev);
  };

  const handlePopoverClick = (value: number) => {
    if (value !== score) {
      handleSave(reqScore, defaultToZero(value));
    }
  };

  const handleSave = (key: string, value: number | string) => {
    handleEvent(onSave, key, value);
  };

  const showAllCommentsOrHideAllDescriptions = showAllComments || !showAllDescriptions;
  return (
    <Fragment>
      <div className={classNames(classes.topAlligned, classes.top)}>
        <div className={classes.left}>
          <div className={classNames(classes.listItemHeader)}>
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent styling={"p2"}>
                  {name || (
                    <TypographyComponent element={"span"} styling={"p2"} rootClassName={classes.nameGCOM3606}>
                      {t("Untitled requirement")}
                    </TypographyComponent>
                  )}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <p className={classNames(classes.name, "p2")}>
                  {name || <span className="italic">{t("Untitled requirement")}</span>}
                </p>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
            <span className={classes.pipe} />
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent
                  rootClassName={classNames(classes.priorityGCOM3606)}
                  styling={"p4"}
                  boldness={"semi"}
                >
                  {getScorePriority(priority)}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <p className={classNames(classes.priority, "p4")}>{getScorePriority(priority)}</p>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          </div>
          <Collapse in={showAllDescriptions}>
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent styling={"p3"} color={"iron"}>
                  {description}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <div className={classes.description}>
                  <p className="p3">{description}</p>
                </div>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          </Collapse>
        </div>
        <div className={classes.right}>
          {isScoringLevelItem && (
            <ScoringPopover isViewOnly={isViewOnly} onSelectScore={handlePopoverClick} score={score} />
          )}
          <MessageIconWithToolTip
            isOpen={showComment}
            hasComments={!!(comments || comment)}
            handleClick={handleCommentsButtonClick}
            isViewOnly={isViewOnly}
          />
        </div>
      </div>
      <Collapse in={showComment}>
        <div className={classNames(classes.bottom, showAllCommentsOrHideAllDescriptions && classes.bottomMargin)}>
          <BaseTextArea
            disabled={isViewOnly}
            flavor="OUTLINED"
            handleAutoSave={(value: number | string) => handleSave(reqComm, value)}
            helperText={t("Comments")}
            minRows={1}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setComment(e?.target?.value)}
            placeholder=" "
            rootClassName="gx-scorecard-list-item-comments"
            value={defaultToEmptyString(comments)}
          />
        </div>
      </Collapse>
    </Fragment>
  );
};

export default RequirementScoreRow;
