import React, { useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./score-form-controls.styles";
import { useTranslation } from "react-i18next";
import { colorPalette } from "gx-npm-common-styles";
import { MiniButton, TooltipV2, IconButton } from "gx-npm-ui";
import { EyeIcon, EyeSlashIcon, GearIcon } from "gx-npm-icons";
import { useScoreFormContext } from "../context/score-form-context";

const useStyles = makeStyles(() => styles);
const ScoreFormControls = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [slideOut, setSlideOut] = useState(true);
  const { showAllComments, setShowAllComments, setShowAllDescriptions, showAllDescriptions } = useScoreFormContext();
  const handleIconClick = () => {
    setSlideOut(!slideOut);
  };
  const handleClickCommentsButton = () => {
    setShowAllComments(!showAllComments);
  };
  const handleClickDescriptionButton = () => {
    setShowAllDescriptions(!showAllDescriptions);
  };
  return (
    <div className={classNames(classes.scoreContentControls)}>
      <div className={classNames(classes.buttonsWrapper)}>
        <div className={classNames(classes.hideShowButtons, "gx-hide-show-buttons-container", slideOut && "slide-out")}>
          {showAllDescriptions ? (
            <MiniButton onClick={handleClickDescriptionButton} rootClassName={"gx-hide-all-description-button"}>
              <EyeSlashIcon fillPath={colorPalette.interactions.blueBerry.hex} />
              {t("Hide all descriptions")}
            </MiniButton>
          ) : (
            <MiniButton onClick={handleClickDescriptionButton} rootClassName={"gx-show-all-description-button"}>
              <EyeIcon fillPath={colorPalette.interactions.blueBerry.hex} />
              {t("Show all descriptions")}
            </MiniButton>
          )}
          <div className={classNames(classes.verticalLine)} />
          {showAllComments ? (
            <MiniButton onClick={handleClickCommentsButton} rootClassName={"gx-show-comments-button"}>
              <EyeSlashIcon fillPath={colorPalette.interactions.blueBerry.hex} />
              {t("Hide all comments")}
            </MiniButton>
          ) : (
            <MiniButton onClick={handleClickCommentsButton} rootClassName={"gx-show-comments-button"}>
              <EyeIcon fillPath={colorPalette.interactions.blueBerry.hex} />
              {t("Show all comments")}
            </MiniButton>
          )}
        </div>
        <TooltipV2
          title={t("View controls for showing and hiding requirement descriptions and comments.")}
          rootClassName={"gx-score-form-controls-icon-tool-tip"}
          placement="top"
          PopperProps={{ modifiers: { offset: { offset: "0px, 8px" } } }}
        >
          <div>
            <IconButton
              onClick={handleIconClick}
              className={classNames("gx-score-form-controls-icon-button", slideOut ? "not-selected" : "selected")}
              borderRadius={!slideOut ? "0px 8px 8px 0px" : "0px"}
              hoverBackgroundColor={colorPalette.interactions.lightBerry.hex}
            >
              <GearIcon />
            </IconButton>
          </div>
        </TooltipV2>
      </div>
    </div>
  );
};

export default ScoreFormControls;
