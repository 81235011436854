const getListHeaderStyles = (sticky = 48, index = -1) => {
  const stickyStyles = {
    position: "sticky" as string as "sticky",
    top: sticky,
    "@media (max-width:1239px)": {
      top: sticky + 32,
    },
  };

  return {
    listHeader: {
      display: "flex",
      backgroundColor: "var(--color-white)",
      borderRadius: "8px 8px 0px 0px",
      padding: 20,
      zIndex: 1024 - index,
      ...(sticky >= 0 ? stickyStyles : {}),
      "&:hover": {
        zIndex: 1025 - index,
      },
      "&.collapse": {
        borderRadius: "8px 8px 8px 8px",
        boxShadow: "none",
        "-webkit-transition": "border-radius",
        "-webkit-transition-duration": "0.25s",
        "-webkit-transition-delay": "0.25s",
        "-webkit-transition-timing-function": "linear",
      },
      "& fieldset": {
        border: 0,
        borderRadius: 0,
      },
    },
  };
};

const listHeaderExpandButtonStyles = {
  iconExpand: {
    float: "left" as string as "left",
    "&:not(.gx-is-expanded) svg": {
      transform: "rotate(180deg)",
    },
    "& button": {
      bottom: 1,
    },
  },
};

const listHeaderTitleStyles = {
  title: {
    width: "100%",
    "& input": {
      color: "var(--color-neutrals-carbon)",
      fontSize: "18px !important",
      fontWeight: "600",
      fontVariationSettings: "'wght' 600",
      lineHeight: "25.2px !important",
      letterSpacing: "0.35px",
    },
  },
};

export { getListHeaderStyles, listHeaderExpandButtonStyles, listHeaderTitleStyles };
