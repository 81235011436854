import { InitStatus, InitUserRole, ScoringMode } from "gx-npm-lib";
import { Button, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import * as singleSpa from "single-spa";
import noScoreCard from "../assets/images/no-scorecard.svg";
import useScoresheet from "../context/use-scoresheet.hook";
import styles from "./no-scores-view.styles.module.scss";

const NoScoresViewComponent = () => {
  const { t } = useTranslation();
  const { initiativeId, initStatus, userRole, scoringMode } = useScoresheet();
  const url = `/s/evaluation/${initiativeId}/scorecard`;

  let title = "";
  if (userRole === InitUserRole.OWNER && initStatus === InitStatus.PLANNING) {
    title = t("The scorecard has not been started.");
  } else if (userRole === InitUserRole.OWNER && scoringMode === ScoringMode.NONE) {
    title = t("Scoring assignments not set.");
  } else if (userRole !== InitUserRole.OWNER && initStatus === InitStatus.PLANNING) {
    title = t("Your scoresheet is unavailable right now because no scorecard has been created for this evaluation.");
  } else if (userRole !== InitUserRole.OWNER && scoringMode === ScoringMode.NONE) {
    title = t("Your scoresheet is unavailable right now because scoring assignments are not set for this evaluation.");
  }

  let subtitle = "";
  if (initStatus === InitStatus.PLANNING) {
    subtitle = t("Owners in your evaluation can start the scorecard for your team.");
  } else if (scoringMode === ScoringMode.NONE) {
    subtitle = t("Owners in your evaluation can set scoring assignments within scorecard.");
  }

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    singleSpa.navigateToUrl(url);
  };

  return (
    <div className={styles.container}>
      <img alt={t("no scorecard image")} className={styles.noScoreImg} src={noScoreCard} />
      <TypographyComponent color="iron" rootClassName={styles.title} type="h4">
        {title}
      </TypographyComponent>
      <TypographyComponent color="coal" rootClassName={styles.subtitle} type="p3">
        {subtitle}
      </TypographyComponent>
      {userRole === InitUserRole.OWNER && (
        <Button href={url} onClick={handleClick} rootClassName="btn-tertiary">
          {t("GO TO SCORECARD TAB")}
        </Button>
      )}
    </div>
  );
};

export default NoScoresViewComponent;
