import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import NoneEvaluatorBanner from "./components/none-evaluator-banner/none-evaluator-banner.component";
import ScorecardForm from "./components/scorecard-form/scorecard-form.component";
import ScoringProgressInformation from "./components/scoring-progress-information/scoring-progress-information.component";
import { scoreContentStyles as styles, sidebarVerticalPadding } from "./score-content.styles";
import SidebarCommentV2Component from "./components/sidebar-comment-v2/sidebar-comment-v2.component";

type ScoreContentProps = {
  hasScrolled: boolean;
  isEvaluator: boolean;
  isViewer: boolean;
  sessionStorageId: string;
};

const useStyles = makeStyles(() => styles);
const ScoreContent: FC<ScoreContentProps> = ({
  hasScrolled = false,
  isEvaluator = true,
  isViewer = false,
  sessionStorageId = "",
}) => {
  const classes = useStyles();

  const refBody = useRef<HTMLDivElement>(null);
  const refSidebar = useRef<HTMLDivElement>(null);
  const [stylesSidebar, setStylesSidebar] = useState({ left: "auto", right: "auto" });
  const [stylesVerticalDivider, setStylesVerticalDivider] = useState({ left: "auto", right: "auto" });
  const isViewOnly = isViewer || !isEvaluator;
  function handleLayout() {
    const dividerWidth = 1;
    const bodyOffsetLeft = refBody.current?.offsetLeft || 0;
    const sidebarWidth = refSidebar.current?.offsetWidth || 0;

    if (window.innerWidth >= 1025) {
      const scrollbarWidth = 10;
      const rightSidebarValue = bodyOffsetLeft - scrollbarWidth;
      const rightVerticalDividerValue =
        bodyOffsetLeft + sidebarWidth + sidebarVerticalPadding - dividerWidth - scrollbarWidth / 2;
      setStylesSidebar({ left: "auto", right: `${rightSidebarValue}px` });
      setStylesVerticalDivider({ left: "auto", right: `${rightVerticalDividerValue}px` });
    } else {
      const rightItemsOffset = 640;
      const leftSidebarValue = rightItemsOffset;
      const leftVerticalDividerValue = rightItemsOffset - bodyOffsetLeft - dividerWidth;
      setStylesSidebar({ left: `${leftSidebarValue}px`, right: "auto" });
      setStylesVerticalDivider({ left: `${leftVerticalDividerValue}px`, right: "auto" });
    }
  }

  useEffect(() => {
    const handleResize = () => {
      handleLayout();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleLayout();
  }, [refBody.current?.offsetWidth]);

  return (
    <Fragment>
      <div
        className={classNames(
          classes.contentContainer,
          !isEvaluator && !isViewer && classes.previewBannerPadding,
          hasScrolled && "scrolled"
        )}
      >
        {!isEvaluator && !isViewer && <NoneEvaluatorBanner />}
        <div className={classNames(classes.cardAndSidebarContainer)}>
          <div className={classNames(classes.scorecardContainer)} ref={refBody}>
            <ScorecardForm sessionStorageId={sessionStorageId} isViewOnly={isViewOnly} />
          </div>
          <div className={classNames(classes.sidebarDashes)} style={stylesVerticalDivider} />
          <div
            className={classNames(classes.sidebarContainer, "gx-sidebar-container")}
            ref={refSidebar}
            style={stylesSidebar}
          >
            <ScoringProgressInformation />
            <SidebarCommentV2Component />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ScoreContent;
